import Vue from 'vue'
import Vuex from 'vuex'
import {Auth} from 'aws-amplify';

Vue.use(Vuex)

/* eslint-disable no-new */

const loginModule = {
  state: {
    username: '',
    isLoggedIn: false,
    sessionToken: '',
    user: '',
    uuid: ''
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setUsername(state, value) {
      state.username = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setSessionToken(state, value) {
      state.sessionToken = value;
    },
    setUuid(state, value) {
      state.uuid = value;
    }
  },
  getters: {
    getLoginStatus: ({isLoggedIn}) => (isLoggedIn),
    getLoggedUser: ({username}) => (username),
    getSessionToken: ({sessionToken}) => (sessionToken),
    getSavedUuid: ({uuid}) => (uuid)
  },
  actions: {
    async setUuid({commit}, {uuid}) {
      commit('setUuid', uuid);
    },
    async signOut({commit}) {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log('error signing out: ', error);
      } finally {
        commit('setLoggedIn', false);
      }
    },

    async signIn({commit}, {username, password}) {
      return await Auth.signIn({username, password,})
        .then(user => {
          commit('setLoggedIn', true);

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

            commit('setUsername', username);
            commit('setUser', user);

            return 1;
          }
          return 0;
        }).catch(error => {
          console.info(error);
          return -1;
        });
    },
    async completeNewPassword({commit, state}, {password}) {
      try {
        await Auth.completeNewPassword(
          state.user,               // the Cognito User Object
          password,       // the new password
        );
      } catch (error) {
        console.info(error);
        return -1;
      }
      commit('setLoggedIn', true);

      return 0;
    },

    async getLoggedInfo({commit}) {
      return await Auth.currentSession()
        .then(async data => {
          commit('setSessionToken', data.getIdToken().getJwtToken());
          commit('setLoggedIn', true);

          return 0;
        })
        .catch(async err => {
          console.log(err);
          return -1;

        });
    }
  }
}

const store = new Vuex.Store({
  plugins: [],
  modules: {
    login: loginModule
  }
})

export default store
