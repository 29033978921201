import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue';
import DownloadPDF from "../pages/DownloadPDF";

Vue.use(VueRouter);
const routes = [

    {
        path: "/",
        name: "Login",
        component: Login
    },
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/pdf/",
        name: "Login",
        component: DownloadPDF
    },
    {
        path: "/pdf/*/*",
        name: "Login",
        component: DownloadPDF
    },
    {
        path: "/pdf/:uuid",
        name: "DownloadPDF",
        component: DownloadPDF,
        props: true,
    }
]


const router = new VueRouter({
    mode: 'history',
    routes
});


export default router
