<template>
  <v-app-bar elevation="3" color="primary" dense fixed app height="140px">
    <v-row>
      <v-col cols="3">
    <v-toolbar-items><img class="logo" src="../assets/logo.png"/>
    </v-toolbar-items>
      </v-col>
      <v-col cols="8">
    <v-toolbar-title class="title white--text"><h2>Card PDF Service</h2></v-toolbar-title>
      </v-col>
        <v-col cols="1">
    <v-toolbar-items>
      <v-icon v-if="getLoginStatus" x-large class="logoutButton" title="Logout" @click="logout" >mdi-logout</v-icon>
    </v-toolbar-items>
        </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  name: 'Header',
  computed:{
    ...mapGetters(['getLoggedUser', 'getLoginStatus', 'getSessionToken']),
  },
  methods:{
    async logout() {
      await this.$store.dispatch("signOut");
      await this.$router.push({name:"Login"});
    }

  }

}

</script>
<style scoped>
.v-toolbar__items {
  display: block;
}


.logoutButton{
  cursor: pointer;
  color:#FB8C00;
  margin:1em;

}
.logoutButton:hover{
  color:#1D86D9;

}
.logo {
  padding-top: 1px;
  width: 15em;
  border-radius: 7px;
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
}

.title {
  padding: 3em;
}
</style>
