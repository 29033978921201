<template>

  <v-container class="login__container" id="loginBox">
    <v-row>
      <v-col cols="12">
        <v-alert
            type="error"
            :dismissible="true"
            class="login__error"
            v-if="error"
        > Login attempt has failed
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="!passwordNeedsReset" dense>
      <v-col cols="12">
        <v-text-field
            v-model="login"
            :counter="true"
            label="Login"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-if="!passwordNeedsReset" cols="12">
        <v-text-field
            v-model="password"
            type="password"
            :counter="true"
            label="Password"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="passwordNeedsReset">
      <v-col>
        Your password needs to be changed. <br> Please enter a new password.
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-if="passwordNeedsReset" cols="12">
        <v-text-field
            v-model="newPassword"
            type="password"
            :counter="true"
            label="New Password"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="loading" class="progressLoader">
          <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
              :width="5"
          ></v-progress-circular>
        </div>
        <div class="buttonHolder" v-else-if="!loading && !passwordNeedsReset">
          <v-btn
              :disabled="!login || !password"
              elevation="2"
              color="primary"
              @click="signIn"
              x-large
          >SIGN IN
          </v-btn>
        </div>
        <div class="buttonHolder" v-else-if="!loading && passwordNeedsReset">
          <v-btn
              :disabled="!newPassword"
              elevation="2"
              color="primary"
              @click="resetPassword"
              x-large
          >Reset Password
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import {mapGetters} from "vuex";

export default ({
  name: 'LoginPage',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Card PDF Service - Login',
  },
  computed: {
    ...mapGetters(['getSavedUuid']),
  },
  async beforeMount() {
    await this.$store.dispatch('getLoggedInfo').then(async result => {
          if (result === 0) {
            await this.$router.push({path: `/pdf/${this.getSavedUuid}`});
          }
        }
    );
  },
  data() {
    return {
      login: '',
      password: '',
      error: '',
      loading: false,
      passwordNeedsReset: false,
      newPassword: '',
    }
  }
  ,
  methods: {
    async signIn() {
      this.loading = true;

      const result = await this.$store.dispatch('signIn', {
        username: this.login,
        password: this.password
      });
      if (result === 1) {
        this.passwordNeedsReset = true;
        this.loading = false;

      } else if (result === 0) {
        await this.$router.push({path: `/pdf/${this.getSavedUuid}`});
      } else {
        this.loading = false;
        this.error = "login"
      }
    },

    async resetPassword() {
      const result = await this.$store.dispatch('completeNewPassword', {
        password: this.newPassword
      });
      if (result === 0) {
        await this.$router.push({path: `/pdf/${this.getSavedUuid}`});
      } else {
        this.loading = false;
        this.error = "login"
      }
    }
  }
})
</script>

<style scoped>
#loginBox {
  max-width: 300px;
  margin-top: 5em;
}

.login__error {
  margin-bottom: 32px;
}

.progressLoader {

  position: fixed;
  transform: translate(-50%, -50%);
  text-align: center;

  top: 55%;
  left: 50%;
}

.buttonHolder {
  text-align: center;
}
</style>
