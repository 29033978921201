<template>
  <div>
    <div v-if="!error &&!success">
      <br>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <v-alert
              type="info"
              :dismissible="true"
          >
            The download will start shortly...
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <div v-if="loading && !error" class="progressLoader">
      <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
          :width="5"
      ></v-progress-circular>
    </div>

    <div v-if="error">
      <br>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <v-alert
              type="error"
              :dismissible="true"
              class="login__error"
          >
            {{ errorText }}
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <div v-if="success">
      <br>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <v-alert
              type="success"
              :dismissible="true"
          >
           Your file downloaded successfully
          </v-alert>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DownloadPDF",
  data() {
    return {
      loading: true,
      error: false,
      success:false,
      errorText: "Unknown error",
      timeoutError:-1
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Card PDF Service - Download',
  },
  computed: {
    ...mapGetters(['getLoggedUser', 'getLoginStatus', 'getSessionToken', 'getSavedUuid']),
  },
  async beforeMount() {
    const isLoggedResult = await this.$store.dispatch('getLoggedInfo');
    if (isLoggedResult === -1 || !this.getLoginStatus) {
      await this.$store.dispatch('setUuid', {uuid: this.getUuid()});
      await this.$router.push({path: '/login'});
    } else {
      this.loading = true;

      this.getPDF();
    }
    this.timeoutError=setTimeout(this.timeout, 30000);
  },
  methods: {
    timeout(){
      this.error = true;
      this.loading = false;
      this.errorText = "Timeout generating the PDF. Please try again later";

    },
    getUuid() {
      let uuidFromPath = this.$route.params.uuid;
      return uuidFromPath ? uuidFromPath : this.getSavedUuid;
    },
    getPDF() {
      console.log(this.getUuid())
      if (!this.getUuid()) {
        this.error = true;
        this.errorText = "The UUID of the file was not provided. Please provide a UUID"
        clearTimeout(this.timeoutError);
        return;
      }
      fetch(process.env.VUE_APP_CARD_PDF_SERVICE_URL + "/" + this.getUuid(), {
        mode: "cors",
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.getSessionToken
        }
      }).then(
          (response) => {
            clearTimeout(this.timeoutError);
            if (response.ok) {
              this.loading = false;
              this.error = false;
              this.success=true;
              return response.text();
            } else {
              //response.status = 500;
              //response.errorMessage = 'Unknown error';
              this.loading = false;
              this.error = true;
              this.errorText = "There was an error with the download";
              return response;
            }


          }
      ).then(
          (data) => {
            const downloadLink = document.createElement("a");
            downloadLink.href = 'data:application/pdf;base64,' + data;
            downloadLink.download = this.getUuid() + '.pdf';
            downloadLink.click();
          }
      ).catch(error => {
        console.error('There has been a problem with your fetch operation:', error);

      });

    }

  }
}

</script>

<style scoped>

.progressLoader {
  position: fixed;
  transform: translate(-50%, -50%);
  text-align: center;
  top: 55%;
  left: 50%;
}


</style>
